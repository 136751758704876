import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../../../Components/Header/Header";
import { hero, metal, service6 } from "../../../assets";

export default function Metal() {

  const { t, i18n } = useTranslation();
  return (
    <>
    <Header/>
    <div className="mx-auto bg-[#FAFAFA] pt-[140px] pb-[144px]">
       <div className="max-w-containerSm md:max-w-container mx-auto">
    <p className="text-[#405d72] text-[20px] font-semibold mb-4">{t("Xidmətlərimiz")}</p>
    <p className="text-black text-[22px] md:text-3xl font-bold mb-10">{t("Metal konstruksiyaların istehsalı və quraşdırılması")}</p>
   <img className=" bg-black/20 rounded-lg w-full" src={`https://fuga.az/fuga/media/image1.png`}></img>
   <p className="text-black text-base font-normal mt-6 text-justify">{t("services7")}</p>
   <ul className="mt-10 list-disc list-inside font-semibold">
  <li>{t("Anqarlar və saxlama sahələri")}</li>
  <li>{t("Məhsul anbarları")}</li>
  <li>{t("Sənaye obyektləri")}</li>
  <li>{t("Fabrik və istehsalat binaları")}</li>
  <li>{t("Avtodayanacaqlar")}</li>
  <li>{t("İdman obyektləri")}</li>
  <li>{t("Əyləncə və ticarət mərkəzləri")}</li>
  <li>{t("Müvəqqəti tikinti sahələri")}</li>
  <li>{t("Binanın və tikililərin dəmir karkasları")}</li>
  <li>{t("Geniş diametrli boru sistemləri")}</li>
  <li>{t("Dəmir yol körpüləri və digər nəqliyyat keçidləri")}</li>
  <li>{t("Arenaların və böyük komplekslərin inşası")}</li>
  <li>{t("Telekommunikasiya sistemləri")}</li>
  <li>{t("Qüllə və rabitə avadanlıqlarının quraşdırılması")}</li>
  <li>{t("Reklam stendləri və dekorativ memarlıq elementləri")}</li>
</ul>



     </div>
     
</div>
    </>
  );
}
