import React from "react";
import { useTranslation } from "react-i18next";
import { arrow, hero, svg1, svg2, svg3, svg4, svg5, svg6, svgWhite1, svgWhite2, svgWhite3, svgWhite4, svgWhite5, svgWhite6, whiteArrow } from "../../../assets";
import Header from "../../../Components/Header/Header";

export default function Temir() {

  const { t, i18n } = useTranslation();
  return (
    <>
    <Header/>
    <div className="mx-auto bg-[#FAFAFA] pt-[140px] pb-[144px]">
       <div className="max-w-containerSm md:max-w-container mx-auto">
    <p className="text-[#405d72] text-[20px] font-semibold mb-4">{t("Xidmətlərimiz")}</p>
    <p className="text-black text-[22px] md:text-3xl font-bold mb-10">{t("Təmir")}</p>
    <div>
          {/* <p className='text-[#1b2335] text-[20px] font-semibold mb-3'>{t("ƏSAS XİDMƏTLƏR")}</p> */}
          <img className=" bg-black/20 rounded-lg w-full" src={`https://fuga.az/fuga/media/image5.png`}></img>
          <p className="text-black text-base font-normal mt-6 text-justify">{t("services1")}</p>
          </div>
     </div>
     
</div>
    </>
  );
}