import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../../Components/Header/Header";
import { hero, service2 } from "../../assets";

export default function Vakansiya() {

  const { t, i18n } = useTranslation();
  return ( 
    <>
    <Header/>
    <div className="mx-auto bg-[#FAFAFA] pt-[147px] pb-[144px] ">
       <div className="md:max-w-container max-w-containerSm mx-auto h-[713px]">
   <div className=" h-[800px] flex justify-between">
    <div className="md:w-[643px] mx-auto">
    <p className="text-[#405d72] text-[20px] font-semibold md:mb-4 text-center">{t("Vakansiyalar")}</p>
    <p className="text-black text-[22px] md:text-3xl font-bold mb-10 text-center">{t("İş üçün bizə müraciət edə bilərsiz")}</p>
 <p className="text-black text-base font-normal mb-2">{t("Ad, soyad")}*</p>
 <input className="bg-[#f4f4f4] rounded-xl h-10 w-full mb-7 p-2" placeholder="Lorem ipsum"></input>
 <p className="text-black text-base font-normal mb-2">{t("E-mail")}*</p>
 <input className="bg-[#f4f4f4] rounded-xl h-10 w-full mb-7 p-2" placeholder="email@gmail.com"></input>
 <p className="text-black text-base font-normal mb-2">{t("Əlaqə nömrəsi")}*</p>
 <input className="bg-[#f4f4f4] rounded-xl h-10 w-full mb-7 p-2"></input>
 <p className="text-black text-base font-normal mb-2">CV*</p>
 <input type="file" className="bg-[#f4f4f4] rounded-xl h-10 w-full mb-7 p-2"></input>
 <p className="text-black text-base font-normal mb-2">{t("Əlavə qeydiniz")}</p>
 <textarea className="bg-[#f4f4f4] rounded-xl h-[114px] w-full mb-7 p-2" placeholder={t("Bizə yazın...")}></textarea>
 <button className="text-base font-normal w-[132px] h-[42px] rounded-xl border bg-[#f1b559] text-[white] hover:bg-[#898989]">{t("Müraciət et")}</button>
    </div>
   </div>
     </div>
     
</div>
    </>
  );
}