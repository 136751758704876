import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../../../Components/Header/Header";
import { carousel1, hero, service4 } from "../../../assets";

export default function TikintiNovleri() {

  const { t, i18n } = useTranslation();
  return (
    <>
    <Header/>
    <div className="mx-auto bg-[#FAFAFA] pt-[140px] pb-[144px]">
       <div className="max-w-container mx-auto ">
    <p className="text-[#405d72] text-[20px] font-semibold mb-4">{t("Xidmətlərimiz")}</p>
    <p className="text-black text-3xl font-bold mb-10">{t("Tikinti növləri")}</p>
   <img className=" bg-black/20 rounded-lg w-full" src={carousel1}></img>
   <p className="text-black text-base font-normal mt-6">{t("services4")}</p>
     </div>
     
</div>
    </>
  );
}
