import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown, FaChevronUp, FaTimes } from 'react-icons/fa';
import { logo, logoOther, mpost } from "../../assets/index";
import { useTranslation, initReactI18next } from "react-i18next";
import { azflag, engflag, rusflag } from '../../assets/index';
import i18n from "i18next";
import enTranslation from "../i18n/en/translation.json";
import azTranslation from "../i18n/az/translation.json";
import rusTranslation from "../i18n/rus/translation.json";

const languageOptions = [
  { value: "en", label: "EN", flag: engflag },
  { value: "az", label: "AZ", flag: azflag },
  { value: "rus", label: "RU", flag: rusflag },
];
 
i18n.use(initReactI18next).init({
  resources: {
      en: { translation: enTranslation }, 
      az: { translation: azTranslation },
      rus: { translation: rusTranslation },
  },
  lng: localStorage.getItem("language") || "az",
  fallbackLng: "az",
  interpolation: { escapeValue: false },
});


const MainHeader = () => {
  const { t, i18n } = useTranslation();
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const productsTimeoutRef = useRef(null);
  const servicesTimeoutRef = useRef(null);
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [showSubmenu2, setShowSubmenu2] = useState(false);

  // Scroll değişimini takip eden useEffect
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  
  const [activeMenu, setActiveMenu] = useState(null);

  const toggleMenu = (menuIndex) => {
    setActiveMenu(activeMenu === menuIndex ? null : menuIndex);
  };

  const toggleHamburgerMenu = () => setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
 



  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions.find(lang => lang.value === i18n.language));

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      const language = languageOptions.find(lang => lang.value === savedLanguage);
      if (language) {
        setSelectedLanguage(language);
        i18n.changeLanguage(language.value);
      }
    }
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    setIsOpen(false);
    i18n.changeLanguage(language.value);
    localStorage.setItem("language", language.value);
  }; 

  return (
    <> 
      <nav
        className={`top-0 left-0 w-full z-10 h-[91px] fixed transition-colors duration-300 ${
          scrollY > 0 ? "bg-[#D7D7D7] shadow-md" : "bg-transparent"
        } `}>
        <div className="md:max-w-container max-w-containerSm mx-auto">
          <div className="mx-auto flex items-center justify-between h-[91px]">
            <div>
              <a href='/'> <img src={`${scrollY > 0 ? logo: logo}`} alt="Logo" className="h-[43px]" /></a>
            </div>
            <div className="hidden lg:flex gap-5 md:w-[807px] justify-between ">
              <a href="/" className={`${scrollY > 0 ? "text-[#f1b559]" : "text-[#f1b559]"} text-base font-normal`}>
                {t("ANA SƏHİFƏ")}
              </a>
              <div
      className="relative inline-block"
      onMouseEnter={() => setShowSubmenu(true)}
      onMouseLeave={() => setShowSubmenu(false)}
    >
      <a
        href="/services"
        className={`${scrollY > 0 ? "text-black" : "text-white"} text-base font-normal`}
      >
        {t("XİDMƏTLƏR")}
      </a>

      {showSubmenu && (
        <div className="absolute bg-white shadow-lg w-[202px] rounded">
          <a
            href="/services/tikinti"
            className="block px-4 py-2 text-black hover:bg-[#f1f1f1] text-base font-normal"
          >
           {t("Tikinti")}
          </a>
          <a
            href="/services/temir"
            className="block px-4 py-2 text-black hover:bg-[#f1f1f1] text-base font-normal"
          >
            {t("Təmir")}
          </a>
          <a
            href="/services/metal"
            className="block px-4 py-2 text-black hover:bg-[#f1f1f1] text-base font-normal"
          >
            {t("Metal konstruksiyasının istehsalı")}
          </a>
        </div>
      )}
    </div>
              <a href="/about" className={`${scrollY > 0 ? "text-black" : "text-white"} text-base font-normal`}>
                {t("HAQQIMIZDA")}
              </a>
              {/* <div
      className="relative inline-block"
      onMouseEnter={() => setShowSubmenu2(true)}
      onMouseLeave={() => setShowSubmenu2(false)}
    >
      <a
        href="/projects"
        className={`${scrollY > 0 ? "text-black" : "text-white"} text-base font-normal`}
      >
        {t("LAYİHƏLƏR")}
      </a>

      {showSubmenu2 && (
        <div className="absolute bg-white shadow-lg w-[202px] rounded">
          <a
            href="/projects/finished"
            className="block px-4 py-2 text-black hover:bg-[#f1f1f1] text-base font-normal"
          >
            Bitmiş layihələr
          </a>
          <a
            href="/projects/unfinished"
            className="block px-4 py-2 text-black hover:bg-[#f1f1f1] text-base font-normal"
          >
            Davam edən layihələr
          </a>
        </div>
      )}
    </div> */}
              <a href="/vakansiya" className={`${scrollY > 0 ? "text-black" : "text-white"} text-base font-normal`}>
                {t("VAKANSİYALAR")}
              </a>
              <a href="/partners" className={`${scrollY > 0 ? "text-black" : "text-white"} text-base font-normal`}>
                {t("PARTNYORLAR")}
              </a>
              <a href="/media" className={`${scrollY > 0 ? "text-black" : "text-white"} text-base font-normal`}>
                {t("MEDİA")}
              </a>
              <a href="/contact" className={`${scrollY > 0 ? "text-black" : "text-white"} text-base font-normal`}>
                {t("ƏLAQƏ")}
              </a>
            </div>
            <div className="flex">
            <div className="flex gap-2 items-center">
      <a href="https://www.instagram.com/fuga.group/profilecard/?igsh=aGIzNXZ5dmlhYW1u">
      
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white"/>
<rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="white"/>
<path d="M8.50356 5.5H15.4964C17.1319 5.5 18.5 6.89691 18.5 8.65919V15.3408C18.5 17.1031 17.1319 18.5 15.4964 18.5H8.50356C6.86805 18.5 5.5 17.1031 5.5 15.3408V8.65919C5.5 6.89691 6.86805 5.5 8.50356 5.5ZM8.50356 5.79084C6.95697 5.79084 5.73595 7.09802 5.73595 8.65919V15.3408C5.73595 16.902 6.95697 18.2092 8.50356 18.2092H15.4964C17.043 18.2092 18.2641 16.902 18.2641 15.3408V8.65919C18.2641 7.09802 17.043 5.79084 15.4964 5.79084H8.50356Z" fill="black" stroke="black"/>
<path d="M14.759 11.9985C14.759 13.6201 13.5003 14.9022 12.0001 14.9022C10.4992 14.9022 9.24121 13.6202 9.24121 11.9985C9.24121 10.3768 10.4992 9.09485 12.0001 9.09485C13.501 9.09485 14.759 10.3768 14.759 11.9985ZM14.5231 11.9993C14.5231 10.5787 13.4121 9.38652 12.0001 9.38652C10.5881 9.38652 9.47716 10.5787 9.47716 11.9993C9.47716 13.4199 10.5881 14.6122 12.0001 14.6122C13.4121 14.6122 14.5231 13.4199 14.5231 11.9993Z" fill="black" stroke="black"/>
<path d="M15.9388 8.22847C15.9388 8.47882 15.7483 8.64555 15.5607 8.64555C15.3725 8.64555 15.1826 8.47912 15.1826 8.22847C15.1826 7.97783 15.3725 7.8114 15.5607 7.8114C15.7488 7.8114 15.9388 7.97783 15.9388 8.22847Z" fill="black" stroke="black"/>
</svg>
      </a> 
     <a href="https://www.facebook.com/share/p/81FyHWAtGG9SdhfA/?mibextid=CTbP7E">
     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 0.5C18.2847 0.5 23.3923 5.54202 23.4983 11.8017V12.0004C23.4983 17.8081 19.1934 22.6103 13.6012 23.3895C13.078 23.4621 12.5426 23.5 11.9992 23.5C11.3716 23.5 10.7558 23.45 10.1567 23.3534L10.1566 23.3533C4.68178 22.4719 0.5 17.7236 0.5 12.0004C0.5 5.64907 5.64892 0.5 12 0.5Z" fill="white" stroke="white"/>
<path d="M14.8024 8.00343C13.9772 8.00343 13.2946 8.61671 13.1858 9.41213L13.1698 9.42801V9.63603V12.2502V12.7502H13.6698H16.3256L15.959 15.2716H13.6698H13.1698V15.7716V23.4412C12.7849 23.48 12.3942 23.5 11.9992 23.5C11.5174 23.5 11.0427 23.4706 10.5771 23.4132V15.7716V15.2716H10.0771H7.59473V12.7502H10.0771H10.5771V12.2502V9.05166C10.5771 7.3778 11.9065 6.01395 13.5676 5.9596H13.6707C13.6867 5.9596 13.701 5.95887 13.713 5.95792H16.4044V8.00343H14.8024Z" stroke="black"/>
</svg>
     </a>
      </div>
              <div className="relative inline-flex items-center space-x-4 mt-4 sm:mt-0 sm:ml-0 flex-wrap sm:flex-nowrap">
            <button
              onClick={toggleDropdown}
              className={`flex items-center space-x-2 px-4 py-2  rounded-full ${scrollY > 0 ? "text-black" : "text-white"}`}
            >
              <span>{selectedLanguage.label}</span>
              <i className={`fas fa-chevron-down ${scrollY > 0 ? "text-black" : "text-white"}`}></i>
            </button>

            {isOpen && (
              <div className="absolute left-0 top-10 mt-2 text-[white] w-20 rounded-md shadow-lg bg-[#F1B559] ring-1 ring-black ring-opacity-5 z-50">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  {languageOptions.map((language) => (
                    <button
                      key={language.value}
                      onClick={() => handleLanguageChange(language)}
                      className="flex items-center space-x-2 px-4 py-2 w-full text-left hover:bg-[#898989]"
                    >
                      <span>{language.label}</span>
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
          <button className="text-[white] focus:outline-none md:hidden" onClick={toggleHamburgerMenu}>
        <i className="fa-solid fa-bars text-[white] text-[24px]"></i>
      </button>

      {isHamburgerMenuOpen && (
        <div className={`fixed inset-y-0 left-0 transform ${isHamburgerMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out bg-white w-full z-50 shadow-lg`}>

          <div className="flex flex-col items-start w-full">
          
           
          
            <div className=" items-center w-[100%] justify-center sm:justify-end h-screen">
              <div className="items-center h-[80px] bg-[#F1F1F1] flex justify-between p-4">
              <div>
              <a href='/'> <img src={logo} alt="Logo" className="h-[43px]" /></a>
            </div> 
          <button className=" text-white p-2 rounded" onClick={toggleHamburgerMenu}>
          <i className="fa-solid fa-xmark text-[black] text-[24px]"></i>
          </button>
              </div>
            <div className="items-center gap-4 h-[300px] p-4"> 

                    {/* Button 3 */}
      <div className="w-full relative ">
        <a href="/">
        <button
          onClick={() => toggleMenu('menu1')}
          className="w-full h-[50px] text-[black] rounded-lg text-left"
        >
          {t("Ana səhifə")}
        </button>
        </a>
      </div>

      {/* Button 1 */} 
      <div className="w-full relative z-50"> {/* Menüye dış z-index ekledik */}
      <button
  onClick={() => toggleMenu('menu2')}
  className="w-full h-[50px] text-[black] rounded-lg flex items-center justify-between"
>
  <span>{t("Xidmətlərimiz")}</span>
  <i className="fas fa-chevron-down text-black"></i>
</button>

  {activeMenu === 'menu2' && (
    <div className="absolute left-0 right-0 mt-2 space-y-2 bg-gray-100 rounded shadow-lg z-50 "> {/* Menüye iç z-index ekledik */}
     <a 
  href="/services/temir" 
  className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out"
>
  {t("Təmir")}
</a>

      <a href="/services/tikinti" className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out">
        {t("Tikinti")}
      </a>
      <a href="/services/metal" className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out">
        {t("Metal konstruksiyaların istehsalı")}
      </a>
    </div>
  )}
</div>

                    {/* Button 3 */}
                    <div className="w-full relative ">
        <a href="/vakansiya">
        <button
          onClick={() => toggleMenu('menu3')}
          className="w-full h-[50px] text-[black] rounded-lg text-left"
        >
          {t("Vakansiyalar")}
        </button>
        </a>
      </div>
      
                    {/* Button 3 */}
                    <div className="w-full relative">
        <a href="/partners">
        <button
          onClick={() => toggleMenu('menu4')}
          className="w-full h-[50px] text-[black] rounded-lg text-left"
        >
          {t("Partnyorlar")}
        </button>
        </a>
      </div>
      
                    {/* Button 3 */}
                    <div className="w-full relative">
        <a href="/media">
        <button
          onClick={() => toggleMenu('menu5')}
          className="w-full h-[50px] text-[black] rounded-lg text-left"
        >
          {t("Media")}
        </button>
        </a>
      </div>
      
                    {/* Button 3 */}
                    <div className="w-full relative mb-5">
        <a href="/contact">
        <button
          onClick={() => toggleMenu('menu6')}
          className="w-full h-[50px] text-[black] rounded-lg text-left"
        >
          {t("Əlaqə")}
        </button>
        </a>
      </div>


    </div>
            <div className="p-4" >
                         <div className="flex">
            <div className="flex gap-2 items-center">
      <a href="https://www.instagram.com/fuga.group/profilecard/?igsh=aGIzNXZ5dmlhYW1u">
      
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white"/>
<rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="white"/>
<path d="M8.50356 5.5H15.4964C17.1319 5.5 18.5 6.89691 18.5 8.65919V15.3408C18.5 17.1031 17.1319 18.5 15.4964 18.5H8.50356C6.86805 18.5 5.5 17.1031 5.5 15.3408V8.65919C5.5 6.89691 6.86805 5.5 8.50356 5.5ZM8.50356 5.79084C6.95697 5.79084 5.73595 7.09802 5.73595 8.65919V15.3408C5.73595 16.902 6.95697 18.2092 8.50356 18.2092H15.4964C17.043 18.2092 18.2641 16.902 18.2641 15.3408V8.65919C18.2641 7.09802 17.043 5.79084 15.4964 5.79084H8.50356Z" fill="black" stroke="black"/>
<path d="M14.759 11.9985C14.759 13.6201 13.5003 14.9022 12.0001 14.9022C10.4992 14.9022 9.24121 13.6202 9.24121 11.9985C9.24121 10.3768 10.4992 9.09485 12.0001 9.09485C13.501 9.09485 14.759 10.3768 14.759 11.9985ZM14.5231 11.9993C14.5231 10.5787 13.4121 9.38652 12.0001 9.38652C10.5881 9.38652 9.47716 10.5787 9.47716 11.9993C9.47716 13.4199 10.5881 14.6122 12.0001 14.6122C13.4121 14.6122 14.5231 13.4199 14.5231 11.9993Z" fill="black" stroke="black"/>
<path d="M15.9388 8.22847C15.9388 8.47882 15.7483 8.64555 15.5607 8.64555C15.3725 8.64555 15.1826 8.47912 15.1826 8.22847C15.1826 7.97783 15.3725 7.8114 15.5607 7.8114C15.7488 7.8114 15.9388 7.97783 15.9388 8.22847Z" fill="black" stroke="black"/>
</svg>
      </a> 
     <a href="https://www.facebook.com/share/p/81FyHWAtGG9SdhfA/?mibextid=CTbP7E">
     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 0.5C18.2847 0.5 23.3923 5.54202 23.4983 11.8017V12.0004C23.4983 17.8081 19.1934 22.6103 13.6012 23.3895C13.078 23.4621 12.5426 23.5 11.9992 23.5C11.3716 23.5 10.7558 23.45 10.1567 23.3534L10.1566 23.3533C4.68178 22.4719 0.5 17.7236 0.5 12.0004C0.5 5.64907 5.64892 0.5 12 0.5Z" fill="white" stroke="white"/>
<path d="M14.8024 8.00343C13.9772 8.00343 13.2946 8.61671 13.1858 9.41213L13.1698 9.42801V9.63603V12.2502V12.7502H13.6698H16.3256L15.959 15.2716H13.6698H13.1698V15.7716V23.4412C12.7849 23.48 12.3942 23.5 11.9992 23.5C11.5174 23.5 11.0427 23.4706 10.5771 23.4132V15.7716V15.2716H10.0771H7.59473V12.7502H10.0771H10.5771V12.2502V9.05166C10.5771 7.3778 11.9065 6.01395 13.5676 5.9596H13.6707C13.6867 5.9596 13.701 5.95887 13.713 5.95792H16.4044V8.00343H14.8024Z" stroke="black"/>
</svg>
     </a>
      </div>
              <div className="relative inline-flex items-center space-x-4 mt-4 sm:mt-0 sm:ml-0 flex-wrap sm:flex-nowrap">
            <button
              onClick={toggleDropdown}
              className={`flex items-center space-x-2 px-4 py-2  rounded-full ${scrollY > 0 ? "text-black" : "text-white"}`}
            >
              <span>{selectedLanguage.label}</span>
              <i className={`fas fa-chevron-down ${scrollY > 0 ? "text-black" : "text-white"}`}></i>
            </button>

            {isOpen && (
              <div className="absolute left-0 top-10 mt-2 text-[white] w-20 rounded-md shadow-lg bg-[#F1B559] ring-1 ring-black ring-opacity-5 z-50">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  {languageOptions.map((language) => (
                    <button
                      key={language.value}
                      onClick={() => handleLanguageChange(language)}
                      className="flex items-center space-x-2 px-4 py-2 w-full text-left hover:bg-[#898989]"
                    >
                      <span>{language.label}</span>
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
          <button className="text-[white] focus:outline-none md:hidden" onClick={toggleHamburgerMenu}>
        <i className="fa-solid fa-bars text-[white] text-[24px]"></i>
      </button>

      {isHamburgerMenuOpen && (
        <div className={`fixed inset-y-0 left-0 transform ${isHamburgerMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out bg-white w-full z-50 shadow-lg`}>

          <div className="flex flex-col items-start w-full">
          
           
          
            <div className=" items-center w-[100%] justify-center sm:justify-end h-screen">
              <div className="items-center h-[80px] bg-[#F1F1F1] flex justify-between p-4">
              <div>
              <a href='/'> <img src={logo} alt="Logo" className="h-[43px]" /></a>
            </div> 
          <button className=" text-white p-2 rounded" onClick={toggleHamburgerMenu}>
          <i className="fa-solid fa-xmark text-[black] text-[24px]"></i>
          </button>
              </div>
            <div className="items-center gap-4 h-[300px] p-4"> 

                    {/* Button 3 */}
      <div className="w-full relative ">
        <a href="/">
        <button
          onClick={() => toggleMenu('menu1')}
          className="w-full h-[50px] text-[black] rounded-lg text-left"
        >
          {t("Ana səhifə")}
        </button>
        </a>
      </div>

      {/* Button 1 */} 
      <div className="w-full relative z-50"> {/* Menüye dış z-index ekledik */}
      <button
  onClick={() => toggleMenu('menu2')}
  className="w-full h-[50px] text-[black] rounded-lg flex items-center justify-between"
>
  <span>{t("Xidmətlərimiz")}</span>
  <i className="fas fa-chevron-down text-black"></i>
</button>

  {activeMenu === 'menu2' && (
    <div className="absolute left-0 right-0 mt-2 space-y-2 bg-gray-100 rounded shadow-lg z-50 "> {/* Menüye iç z-index ekledik */}
     <a 
  href="/services/temir" 
  className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out"
>
  {t("Təmir")}
</a>

      <a href="/services/tikinti" className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out">
        {t("Tikinti")}
      </a>
      <a href="/services/metal" className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out">
        {t("Metal konstruksiyaların istehsalı")}
      </a>
    </div>
  )}
</div>

                    {/* Button 3 */}
                    <div className="w-full relative ">
        <a href="/vakansiya">
        <button
          onClick={() => toggleMenu('menu3')}
          className="w-full h-[50px] text-[black] rounded-lg text-left"
        >
          {t("Vakansiyalar")}
        </button>
        </a>
      </div>
      
                    {/* Button 3 */}
                    <div className="w-full relative">
        <a href="/partners">
        <button
          onClick={() => toggleMenu('menu4')}
          className="w-full h-[50px] text-[black] rounded-lg text-left"
        >
          {t("Partnyorlar")}
        </button>
        </a>
      </div>
      
                    {/* Button 3 */}
                    <div className="w-full relative">
        <a href="/media">
        <button
          onClick={() => toggleMenu('menu5')}
          className="w-full h-[50px] text-[black] rounded-lg text-left"
        >
          {t("Media")}
        </button>
        </a>
      </div>
      
                    {/* Button 3 */}
                    <div className="w-full relative mb-5">
        <a href="/contact">
        <button
          onClick={() => toggleMenu('menu6')}
          className="w-full h-[50px] text-[black] rounded-lg text-left"
        >
          {t("Əlaqə")}
        </button>
        </a>
      </div>


    </div>
            <div className="p-4" >
                         <div className="flex">
            <div className="flex gap-2 items-center">
      <a href="https://www.instagram.com/fuga.group/profilecard/?igsh=aGIzNXZ5dmlhYW1u">
      
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white"/>
<rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="white"/>
<path d="M8.50356 5.5H15.4964C17.1319 5.5 18.5 6.89691 18.5 8.65919V15.3408C18.5 17.1031 17.1319 18.5 15.4964 18.5H8.50356C6.86805 18.5 5.5 17.1031 5.5 15.3408V8.65919C5.5 6.89691 6.86805 5.5 8.50356 5.5ZM8.50356 5.79084C6.95697 5.79084 5.73595 7.09802 5.73595 8.65919V15.3408C5.73595 16.902 6.95697 18.2092 8.50356 18.2092H15.4964C17.043 18.2092 18.2641 16.902 18.2641 15.3408V8.65919C18.2641 7.09802 17.043 5.79084 15.4964 5.79084H8.50356Z" fill="black" stroke="black"/>
<path d="M14.759 11.9985C14.759 13.6201 13.5003 14.9022 12.0001 14.9022C10.4992 14.9022 9.24121 13.6202 9.24121 11.9985C9.24121 10.3768 10.4992 9.09485 12.0001 9.09485C13.501 9.09485 14.759 10.3768 14.759 11.9985ZM14.5231 11.9993C14.5231 10.5787 13.4121 9.38652 12.0001 9.38652C10.5881 9.38652 9.47716 10.5787 9.47716 11.9993C9.47716 13.4199 10.5881 14.6122 12.0001 14.6122C13.4121 14.6122 14.5231 13.4199 14.5231 11.9993Z" fill="black" stroke="black"/>
<path d="M15.9388 8.22847C15.9388 8.47882 15.7483 8.64555 15.5607 8.64555C15.3725 8.64555 15.1826 8.47912 15.1826 8.22847C15.1826 7.97783 15.3725 7.8114 15.5607 7.8114C15.7488 7.8114 15.9388 7.97783 15.9388 8.22847Z" fill="black" stroke="black"/>
</svg>
      </a> 
     <a href="https://www.facebook.com/share/p/81FyHWAtGG9SdhfA/?mibextid=CTbP7E">
     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 0.5C18.2847 0.5 23.3923 5.54202 23.4983 11.8017V12.0004C23.4983 17.8081 19.1934 22.6103 13.6012 23.3895C13.078 23.4621 12.5426 23.5 11.9992 23.5C11.3716 23.5 10.7558 23.45 10.1567 23.3534L10.1566 23.3533C4.68178 22.4719 0.5 17.7236 0.5 12.0004C0.5 5.64907 5.64892 0.5 12 0.5Z" fill="white" stroke="white"/>
<path d="M14.8024 8.00343C13.9772 8.00343 13.2946 8.61671 13.1858 9.41213L13.1698 9.42801V9.63603V12.2502V12.7502H13.6698H16.3256L15.959 15.2716H13.6698H13.1698V15.7716V23.4412C12.7849 23.48 12.3942 23.5 11.9992 23.5C11.5174 23.5 11.0427 23.4706 10.5771 23.4132V15.7716V15.2716H10.0771H7.59473V12.7502H10.0771H10.5771V12.2502V9.05166C10.5771 7.3778 11.9065 6.01395 13.5676 5.9596H13.6707C13.6867 5.9596 13.701 5.95887 13.713 5.95792H16.4044V8.00343H14.8024Z" stroke="black"/>
</svg>
     </a>
      </div>
              <div className="relative inline-flex items-center space-x-4 mt-4 sm:mt-0 sm:ml-0 flex-wrap sm:flex-nowrap">
            <button
              onClick={toggleDropdown}
              className={`flex items-center space-x-2 px-4 py-2  rounded-full ${scrollY > 0 ? "text-black" : "text-white"}`}
            >
              <span>{selectedLanguage.label}</span>
              <i className={`fas fa-chevron-down ${scrollY > 0 ? "text-black" : "text-white"}`}></i>
            </button>

            {isOpen && (
              <div className="absolute left-2 top-10 mt-2 text-[white] w-28 rounded-md shadow-lg bg-[#F1B559] ring-1 ring-black ring-opacity-5 z-50">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  {languageOptions.map((language) => (
                    <button
                      key={language.value}
                      onClick={() => handleLanguageChange(language)}
                      className="flex items-center space-x-2 px-4 py-2 w-full text-left hover:bg-[#898989]"
                    >
                      <span>{language.label}</span>
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
          <button className="text-[white] focus:outline-none md:hidden" onClick={toggleHamburgerMenu}>
        <i className="fa-solid fa-bars text-[white] text-[24px]"></i>
      </button>

      {isHamburgerMenuOpen && (
        <div className={`fixed inset-y-0 left-0 transform ${isHamburgerMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out bg-white w-full z-50 shadow-lg`}>

          <div className="flex flex-col items-start w-full">
          
           
          
            <div className=" items-center w-[100%] justify-center sm:justify-end h-screen">
              <div className="items-center h-[80px] bg-[#F1F1F1] flex justify-between p-4">
              <div>
              <a href='/'> <img src={logo} alt="Logo" className="h-[43px]" /></a>
            </div> 
          <button className=" text-white p-2 rounded" onClick={toggleHamburgerMenu}>
          <i className="fa-solid fa-xmark text-[black] text-[24px]"></i>
          </button>
              </div>
            <div className="items-center gap-4 h-[300px] p-4"> 

                    {/* Button 3 */}
      <div className="w-full relative ">
        <a href="/">
        <button
          onClick={() => toggleMenu('menu1')}
          className="w-full h-[50px] text-[black] rounded-lg text-left"
        >
          {t("Ana səhifə")}
        </button>
        </a>
      </div>

      {/* Button 1 */} 
      <div className="w-full relative z-50"> {/* Menüye dış z-index ekledik */}
      <button
  onClick={() => toggleMenu('menu2')}
  className="w-full h-[50px] text-[black] rounded-lg flex items-center justify-between"
>
  <span>{t("Xidmətlərimiz")}</span>
  <i className="fas fa-chevron-down text-black"></i>
</button>

  {activeMenu === 'menu2' && (
    <div className="absolute left-0 right-0 mt-2 space-y-2 bg-gray-100 rounded shadow-lg z-50 "> {/* Menüye iç z-index ekledik */}
     <a 
  href="/services/temir" 
  className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out"
>
  {t("Təmir")}
</a>

      <a href="/services/tikinti" className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out">
        {t("Tikinti")}
      </a>
      <a href="/services/metal" className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out">
        {t("Metal konstruksiyaların istehsalı")}
      </a>
    </div>
  )}
</div>

                    {/* Button 3 */}
                    <div className="w-full relative ">
        <a href="/vakansiya">
        <button
          onClick={() => toggleMenu('menu3')}
          className="w-full h-[50px] text-[black] rounded-lg text-left"
        >
          {t("Vakansiyalar")}
        </button>
        </a>
      </div>
      
                    {/* Button 3 */}
                    <div className="w-full relative">
        <a href="/partners">
        <button
          onClick={() => toggleMenu('menu4')}
          className="w-full h-[50px] text-[black] rounded-lg text-left"
        >
          {t("Partnyorlar")}
        </button>
        </a>
      </div>
      
                    {/* Button 3 */}
                    <div className="w-full relative">
        <a href="/media">
        <button
          onClick={() => toggleMenu('menu5')}
          className="w-full h-[50px] text-[black] rounded-lg text-left"
        >
          {t("Media")}
        </button>
        </a>
      </div>
      
                    {/* Button 3 */}
                    <div className="w-full relative mb-5">
        <a href="/contact">
        <button
          onClick={() => toggleMenu('menu6')}
          className="w-full h-[50px] text-[black] rounded-lg text-left"
        >
          {t("Əlaqə")}
        </button>
        </a>
      </div>


    </div>
            <div className="p-4" >
            <div className="flex gap-2 items-center ">
      <a href="https://www.instagram.com/fuga.group/profilecard/?igsh=aGIzNXZ5dmlhYW1u">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="#F1B559"/>
<rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="white"/>
<path d="M8.50356 5.5H15.4964C17.1319 5.5 18.5 6.89691 18.5 8.65919V15.3408C18.5 17.1031 17.1319 18.5 15.4964 18.5H8.50356C6.86805 18.5 5.5 17.1031 5.5 15.3408V8.65919C5.5 6.89691 6.86805 5.5 8.50356 5.5ZM8.50356 5.79084C6.95697 5.79084 5.73595 7.09802 5.73595 8.65919V15.3408C5.73595 16.902 6.95697 18.2092 8.50356 18.2092H15.4964C17.043 18.2092 18.2641 16.902 18.2641 15.3408V8.65919C18.2641 7.09802 17.043 5.79084 15.4964 5.79084H8.50356Z" fill="white" stroke="white"/>
<path d="M14.759 11.9985C14.759 13.6201 13.5003 14.9022 12.0001 14.9022C10.4992 14.9022 9.24121 13.6202 9.24121 11.9985C9.24121 10.3768 10.4992 9.09485 12.0001 9.09485C13.501 9.09485 14.759 10.3768 14.759 11.9985ZM14.5231 11.9993C14.5231 10.5787 13.4121 9.38652 12.0001 9.38652C10.5881 9.38652 9.47716 10.5787 9.47716 11.9993C9.47716 13.4199 10.5881 14.6122 12.0001 14.6122C13.4121 14.6122 14.5231 13.4199 14.5231 11.9993Z" fill="white" stroke="white"/>
<path d="M15.9388 8.22847C15.9388 8.47882 15.7483 8.64555 15.5607 8.64555C15.3725 8.64555 15.1826 8.47912 15.1826 8.22847C15.1826 7.97783 15.3725 7.8114 15.5607 7.8114C15.7488 7.8114 15.9388 7.97783 15.9388 8.22847Z" fill="white" stroke="white"/>
</svg>
      </a> 
     <a href="https://www.facebook.com/share/p/81FyHWAtGG9SdhfA/?mibextid=CTbP7E">
     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 0.5C18.2847 0.5 23.3923 5.54202 23.4983 11.8017V12.0004C23.4983 17.8081 19.1934 22.6103 13.6012 23.3895C13.078 23.4621 12.5426 23.5 11.9992 23.5C11.3716 23.5 10.7558 23.45 10.1567 23.3534L10.1566 23.3533C4.68178 22.4719 0.5 17.7236 0.5 12.0004C0.5 5.64907 5.64892 0.5 12 0.5Z" fill="#F1B559" stroke="white"/>
<path d="M14.8024 8.00342C13.9772 8.00342 13.2946 8.6167 13.1858 9.41213L13.1698 9.428V9.63603V12.2502V12.7502H13.6698H16.3256L15.959 15.2716H13.6698H13.1698V15.7716V23.4412C12.7849 23.48 12.3942 23.5 11.9992 23.5C11.5174 23.5 11.0427 23.4706 10.5771 23.4132V15.7716V15.2716H10.0771H7.59473V12.7502H10.0771H10.5771V12.2502V9.05165C10.5771 7.37779 11.9065 6.01395 13.5676 5.95959H13.6707C13.6867 5.95959 13.701 5.95886 13.713 5.95791H16.4044V8.00342H14.8024Z" fill="#F1B559" stroke="white"/>
</svg>
     </a>
      </div>

            </div>

          </div>
          </div>
        </div>
      )}   
            </div>
              {/* <button
              onClick={toggleDropdown}
              className="flex items-center space-x-1 px-3 py-1 border rounded-full border-[#264D92] text-sm sm:text-base mt-2 sm:mt-0"
              style={{ minWidth: "70px" }}
            >
              <img
                src={selectedLanguage.flag}
                alt={`${selectedLanguage.label} Flag`}
                className="h-4 w-4 sm:h-5 sm:w-5 mr-1"
              />
              <span className="text-black">{selectedLanguage.label}</span>
            </button>
            {isOpen && (
              <div className="absolute  w-28 rounded-md shadow-lg bg-[#264D92] ring-1 ring-black ring-opacity-5 z-50">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  {languageOptions.map((language) => (
                    <button
                      key={language.value}
                      onClick={() => handleLanguageChange(language)}
                      className="flex items-center space-x-2 px-4 py-2 w-full text-left hover:bg-[#5e8ee0]"
                    >
                      <img src={language.flag} alt={`${language.label} Flag`} className="h-4 w-4 mr-1" />
                      <span>{language.label}</span>
                    </button>
                  ))}
                </div>
              </div>
            )} */}
            </div>

          </div>
          </div>
        </div>
      )}   
            </div>
              {/* <button
              onClick={toggleDropdown}
              className="flex items-center space-x-1 px-3 py-1 border rounded-full border-[#264D92] text-sm sm:text-base mt-2 sm:mt-0"
              style={{ minWidth: "70px" }}
            >
              <img
                src={selectedLanguage.flag}
                alt={`${selectedLanguage.label} Flag`}
                className="h-4 w-4 sm:h-5 sm:w-5 mr-1"
              />
              <span className="text-black">{selectedLanguage.label}</span>
            </button>
            {isOpen && (
              <div className="absolute  w-28 rounded-md shadow-lg bg-[#264D92] ring-1 ring-black ring-opacity-5 z-50">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  {languageOptions.map((language) => (
                    <button
                      key={language.value}
                      onClick={() => handleLanguageChange(language)}
                      className="flex items-center space-x-2 px-4 py-2 w-full text-left hover:bg-[#5e8ee0]"
                    >
                      <img src={language.flag} alt={`${language.label} Flag`} className="h-4 w-4 mr-1" />
                      <span>{language.label}</span>
                    </button>
                  ))}
                </div>
              </div>
            )} */}
            </div>

          </div>
          </div>
        </div>
      )}   
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default MainHeader;
