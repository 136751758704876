import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { allInOne, barkodOxuyucu, barkodPrinter, barkodProcessor, cardReader, contact, dataTerminal, hero, homeContact, pulQutulari } from '../../../assets/index';
import { useTranslation } from 'react-i18next';


const Contact = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
    <div>
    <div className="pt-[80px] pb-[140px] max-w-containerSm md:max-w-container mx-auto ">
      <div
        style={{ backgroundImage: `url(${homeContact})` }}
        className="h-[478px] flex justify-center items-center bg-cover bg-center rounded-lg"
      >
        <div className="h-[163px] w-[490px] text-center">
          <p className="mb-4 text-white text-3xl font-bold">{t("Layihəniz Üçün Bizimlə Danışın")}</p>
          <p className="mb-4 text-center text-white text-lg font-normal">
            {t("Layihəniz haqqında danışmaq və ya suallarınıza cavab vermək üçün bizimlə əlaqə saxlayın.")}
          </p>
          <a href="/contact">
            <button className="text-base font-normal w-[132px] h-[42px] rounded-xl  bg-[#f1b559] text-white hover:bg-[#898989]">
              {t("Əlaqə")}
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
  
  );
};

export default Contact;
